import React, { memo } from 'react';

import cn from 'classnames';
import queryString from 'query-string';

import TwitterIcon from '../../../assets/images/social_icons/twitter.svg';
import FacebookIcon from '../../../assets/images/social_icons/facebook.svg';
import ShareIcon from '../../../assets/images/social_icons/share.svg';

import classes from './share_icons.module.css';

const getShareLink = (type, text, link) => {
	switch (type) {
	case 'twitter':
		return `https://www.twitter.com/home?${queryString.stringify({ status: text })}`;
	case 'facebook':
		return `https://www.facebook.com/sharer.php?s=100&p[url]=${link}}`;
	case 'mail':
		return `mailto:john.doe@mail.com?subject=${text.substring(0, 40)}...&body=${text}`
	default: return null;
	}
}

const typesIcons = Object.freeze({
	mail: ShareIcon,
	twitter: TwitterIcon,
	facebook: FacebookIcon
});

const ShareIcons = memo(({
	text,
	link,
	toShow = Object.keys(typesIcons),
	className,
	iconContainerClassName,
	iconClassName
}) => {
	return (
		<div className={cn(classes.container, className)}>
			{toShow.map((type) => {
				if (!typesIcons[type]) {
					return null;
				}
				const Icon = typesIcons[type];
				return (
					<a
						key={`share_to_${type}`}
						className={cn(classes.iconContainer, iconContainerClassName)}
						href={getShareLink(type, text, link)}
						target="_blank"
						rel="noreferrer noopener"
					>
						<Icon className={cn(classes.icon, iconClassName)} />
					</a>
				)
			})}
		</div>
	);
});

export default ShareIcons
