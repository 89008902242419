import React from 'react';
import { Link } from 'gatsby';

import cn from 'classnames';

import Button from '../button/button';

import ArrowRight from '../../../assets/images/views/pagination/right-arrow.svg';

import classes from './pagination.module.css';

const Pagination = ({
	className,
	total,
	current,
	useLink,
	linkPathBuild = index => `/${index}`
}) => (
	<div className={cn(classes.container, className)}>
		<ConditionnalArrow
			className={classes.prev}
			arrowClassName={classes.prevIcon}
			link={current !== 0 && linkPathBuild(0)}
		/>
		{[...new Array(total)].fill().map((empty, index) => {
			const isCurrent = index === current;
			const button = (
				<Button
					key={`pagination_button_{index}`}
					className={cn(classes.button, isCurrent && classes.current)}
					disabled={isCurrent}
					color={isCurrent ? 'emerald' : 'primary'}
					{...!isCurrent && {
						style: {
							color: '#6cc0ba'
						}
					}}
				>
					{index + 1}
				</Button>
			);
			if (useLink) {
				return (
					<Link
						key={`pagination_link_${index}`}
						to={linkPathBuild(index)}
					>
						{button}
					</Link>
				);
			}
			return button;
		})}
		<ConditionnalArrow
			className={classes.next}
			arrowClassName={classes.nextIcon}
			link={current < total - 1 && linkPathBuild(current + 1)}
		/>
	</div>
);

const ConditionnalArrow = ({ className, arrowClassName, link }) => {
	const canLink = Boolean(link);
	const arrow = (
		<div
			className={cn(className, !canLink && classes.notAllowedArrowContainer)}
		>
			<ArrowRight
				className={cn(classes.icon, arrowClassName)}
			/>
		</div>
	);
	if (canLink) {
		return (
			<Link to={link}>
				{arrow}
			</Link>
		);
	}
	return arrow;
}

export default Pagination;
