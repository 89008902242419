import React from 'react';

import HelmetLayout from '../components/layouts/helmet_layout/helmet_layout';
import MainLayout from '../components/layouts/main_layout/main_layout';

import JobsHeader from '../components/jobs/jobs_header/jobs_header';
import JobOffersSection from '../components/jobs/jobs_offers_section/jobs_offers_section';

const Jobs = ({ pageContext }) => (
	<HelmetLayout>
		<MainLayout
			footerProps={{
				dismissedSections: ['create-demo-account', 'trusting-customers-list']
			}}
		>
			<JobsHeader />
			<JobOffersSection {...{ pageContext }} />
		</MainLayout>
	</HelmetLayout>
);

export default Jobs;
