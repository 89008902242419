import React from 'react';

import Header from '../../small_views/header/header';
import PlayVideo from '../../small_views/play_video/play_video';

import Background from '../../../assets/images/pages/jobs/header/background.png';
import BackgroundLowDef from '../../../assets/images/pages/jobs/header/background_low_def.png';

import classes from './jobs_header.module.css';

const JobsHeader = () => (
	<Header
		dismissButtons
		className={classes.container}
		background={Background}
		backgroundLowDef={BackgroundLowDef}
		titleClassName={classes.title}
		title={(
			<>
				<Title />
			</>
		)}
	>
		<div className={classes.playVideosContainer}>
			<PlayVideo
				className={classes.playVideo}
				pulseColor="light"
				title="Le mot du CTO"
				duration="3:55"
				changeTextColorOnHover="primary"
			/>
			<PlayVideo
				className={classes.playVideo}
				pulseColor="light"
				title="Le mot de la CSM"
				duration="2:37"
				changeTextColorOnHover="primary"
			/>
		</div>
	</Header>
);

const Title = () => (
	<>
		{"Karnott, c'est avant tout une aventure,"}
		<br />
		{"et comme toute aventure, elle a besoin d'explorateurs, "}
		<br />
		{'de guides et de pionniers. '}
	</>
);

export default JobsHeader;
