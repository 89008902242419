import React, { useCallback, useState } from 'react';

import Tag from '../../../design_system/tag/tag';
import Button from '../../../design_system/button/button';
import SharesIcons from '../../../small_views/share_icons/share_icons';

import classes from './job_offer_item.module.css';

const JobOfferItem = ({
	id, title, tags, description
}) => {
	if (!id) {
		return null;
	}
	const link = 'https://www.welcometothejungle.co/fr/companies/karnott';
	return (
		<div className={classes.container}>
			<div className={classes.title}>
				{title}
			</div>
			<div className={classes.tagsContainer}>
				{tags.map(tag => (
					<Tag
						className={classes.tag}
						key={`tag_${tag.label}`}
						{...tag}
					/>
				))}
			</div>
			<div className={classes.description}>
				{description}
			</div>
			<div className={classes.actionsRow}>
				<ColorChangingButton
					underlineClassName={classes.shareUnderline}
				>
					<SharesIcons
						toShow={['mail']}
						iconContainerClassName={classes.shareIconContainer}
						iconClassName={classes.shareIcon}
						text={`Karnott recrute ! ${link}`}
						{...{ link }}
					/>
				</ColorChangingButton>
				<a
					href="https://www.welcometothejungle.co/fr/companies/karnott"
					target="_blank"
					rel="noreferrer noopener"
				>
					<ColorChangingButton>
						{'En savoir plus >'}
					</ColorChangingButton>
				</a>
			</div>
		</div>
	);
}

const ColorChangingButton = ({ children, ...other }) => {
	const [color, setColor] = useState('emerald');
	const setColorPrimary = useCallback(() => setColor('primary'));
	const setColorEmerald = useCallback(() => setColor('emerald'));
	return (
		<Button
			className={classes.button}
			variant="underlined"
			bounce={false}
			onMouseEnter={setColorPrimary}
			onMouseLeave={setColorEmerald}
			onFocus={setColorPrimary}
			onBlur={setColorEmerald}
			{...{ color }}
			{...other}
		>
			{children}
		</Button>
	);
}

export default JobOfferItem;
