import React, { Fragment } from 'react';

import Section from '../../design_system/section/section';
import Button from '../../design_system/button/button';
import Pagination from '../../design_system/pagination/pagination';
import JobOfferItem from './job_offer_item/job_offer_item';

import classes from './jobs_offers_section.module.css';

const JobOffersSection = ({ pageContext }) => {
	if (!pageContext) {
		return null;
	}
	const { jobOffers, pageCount, currentPage } = pageContext;
	return (
		<Section withClassName={classes.container}>
			<div className={classes.allJobsTitleList}>
				<div className={classes.allOffersTitle}>
					{`Toutes nos offres (${jobOffers.length})`}
				</div>
				<JobOffersList
					{...{ jobOffers }}
				/>
			</div>
			<Pagination
				className={classes.pagination}
				useLink
				linkRootPath="/recruitment"
				total={pageCount}
				current={currentPage}
				linkPathBuild={index => `/jobs${index > 0 ? `/${index + 1}` : ''}#offers`}
			/>
			<DeposeSpontaneousCandidature />
		</Section>
	);
};

const JobOffersList = ({ jobOffers }) => {
	if (!jobOffers) {
		return null;
	}
	return (
		<div
			id="offers"
			className={classes.jobOffersList}
		>
			{jobOffers.map(([id, jobOffer], index) => (
				<Fragment key={`job_offer_small_item_${id}`}>
					<JobOfferItem
						{...{ id }}
						{...jobOffer}
					/>
				{index !== jobOffers.length - 1 && <Separator />}
				</Fragment>
			))}
		</div>
	);
}

const Separator = () => (
	<div className={classes.separatorLineContainer}>
		<div className={classes.separatorLine} />
	</div>
);

const DeposeSpontaneousCandidature = () => (
	<div className={classes.deposeSpontaneousCandidature}>
		<span className={classes.deposeSpontaneousCandidatureTitle}>
			{'Aucune offre ne correspond à votre profil ?'}
		</span>
		<span className={classes.deposeSpontaneousCandidatureSubtitle}>
			{`Mais vous aimeriez faire partie de l’aventure ? N’hésitez pas à nous envoyer votre candidature spontanée,
nous recherchons toujours de nouveaux explorateurs qui aiment (vraiment) travailler en équipe.`}
		</span>
		<a
			href="https://www.welcometothejungle.co/fr/companies/karnott"
			target="_blank"
			rel="noreferrer noopener"
		>
			<Button
				className={classes.deposeSpontaneousCandidatureButton}
				color="emerald"
				variant="contained"
			>
				{'candidature spontannée'}
			</Button>
		</a>
	</div>
);

export default JobOffersSection;
